@use "~@otto-ec/global-frontend-settings/gfs" as gfs;
@use "~@otto-ec/global-frontend-settings/scss/grid" as grid;
@use "variables" as nav-vars;
@use "~@otto-ec/global-frontend-settings/scss/variables" as vars;

.nav-sidebar-content {
  display: none;
  margin: 35px 0;
  overflow: hidden;

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    display: block;
  }

  .nav-sidebar-text {
    .p_unorderedList100 {
      margin-left: 20px;
    }

    li {
      margin-bottom: 0.8em;
    }
  }

  .nav-sidebar-text {
    p, a, h2, .p_unorderedList100 {
      color: nav-vars.$seoText;
      font-size: 12px;
    }

    a, h2 {
      margin: 0;
    }

    p, a {
      line-height: 15px;
    }

    p {
      margin: 0.8em 0;
    }

    .p_unorderedList100 {
      margin-bottom: 0.8em;
    }

    .p_line100 {
      margin: 1em 0;
    }

    h2 {
      line-height: 14px;
      font-weight: bold;
    }

    .black100, .black100 * {
      color: vars.$black100;
    }

    h2.nav-sidebar-headline {
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
    }
  }
}

/*                 */
.nav-top-spacing100 {
  margin-top: .9em;
}

.nav-top-spacing200 {
  margin-top: 1.4em;
}